import React, { Component, Fragment, Prompt } from 'react';
import { Menu, Dropdown, Icon, Label } from '../../node_modules/semantic-ui-react';
import { headerInfo } from '../utils/functions.js';
import './styles.css';

import { getTimeTextFromUnix } from '../utils/functions.js';

export default class HeaderMobile extends Component {

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }


  handleItemClick(e, data) {
    this.props.setComponentViewed(data.name, null);
  };

  render() {
    let refreshText = "Actualizado a las " + getTimeTextFromUnix(this.props.lastRefreshUnix);

    return (
      <div style={{ backgroundColor: "#db2828", height: "100%" }}>
        <div style={{ float: "left", marginTop: "10px", width: "15%" }}>
          <Dropdown
            style={{ display: "inline-block", color: "#ffffff" }}
            icon="bars"
            floating
            labeled
            button
          >
            <Dropdown.Menu>
              <Dropdown.Item
                name="MainPanel"
                active={this.props.componentViewed === 'MainPanel'}
                onClick={(e, data) => this.handleItemClick(e, data)}
                className="menu-icon"
                text={headerInfo("name", "MainPanel")}
                icon={headerInfo("icon", "MainPanel")}
              />
              <Dropdown.Item
                name="Statistics"
                active={this.props.componentViewed === 'Statistics'}
                onClick={(e, data) => this.handleItemClick(e, data)}
                className="menu-icon"
                text={headerInfo("name", "Statistics")}
                icon={headerInfo("icon", "Statistics")}
              />
              <Dropdown.Item
                name="Links"
                active={this.props.componentViewed === 'Links'}
                onClick={(e, data) => this.handleItemClick(e, data)}
                className="menu-icon"
                text={headerInfo("name", "Links")}
                icon={headerInfo("icon", "Links")}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div style={{ display: "inline-block", width: "30%" }}>
          <p style={{ fontStyle: "italic", fontWeight: "600", color: "#ffffff" }}>Velero Aventura - Sistema Gaviota</p>
        </div>
        <div style={{ float: "right", display: "inline-block", width: "30%" }}>
          <p
            style={{display:"inline", color: "#ffffff"}}
          > {refreshText} </p>
          <button style={{display: "inline", background: "rgb(225, 81, 81)" }}>
            <Icon name="refresh" style={{color: "rgb(255,255,255)"}} onClick={this.props.fetch}/>
          </button>
        </div>

      </div>
    );
  }
}
