import React, {Component} from 'react';
import { Container, Grid, Dropdown} from 'semantic-ui-react';
import {statInfo} from '../utils/functions.js';
import Chart from 'chart.js';
import DatePicker,{ registerLocale, setDefaultLocale, getDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

class Statistics extends Component{

  constructor(props) {
    super(props);
    this.firstMinDate = new Date();
    if (window.screen.width > 1200){
      this.firstMinDate.setTime(this.firstMinDate.getTime()-7*86400000)
    }else{
      this.firstMinDate.setTime(this.firstMinDate.getTime()-3*86400000)
    }
    this.firstMaxtDate = new Date();

    this.minDate = new Date();
    this.minDate.setTime(this.minDate.getTime()-30*86400000)
    this.maxDate = new Date();

    this.state={
    }

    this.drawChart = this.drawChart.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.chart = null;

    registerLocale('es', es);
  }

  componentWillMount(){
    this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    switch(event.keyCode) {
        case 38:
            this.props.setStatIndexSelected(0,"minus");
            break;
        case 40:
            this.props.setStatIndexSelected(0,"plus");
            break;
        default:
            break;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidMount(){
    this.drawChart(this.props);
    //this.drawChart(this.props);
  }

  componentWillReceiveProps(nextProps){
    if (nextProps !== this.props){
      this.drawChart(nextProps);
    }
  }

  componentDidUpdate(){
    this.drawChart(this.props);
  }

  handleChangeDate(dateNumber,date){
    if (dateNumber === 0){
      let newDate = new Date();
      let offset = 60000*newDate.getTimezoneOffset();
      let day = Math.floor(date.getTime()/86400000);
      newDate.setTime(86400000*day + offset);
      this.props.setStatDateSelected(0,newDate);
    }else if (dateNumber === 1){
      let newDate = new Date();
      let offset = 60000*newDate.getTimezoneOffset();
      let day = Math.floor(date.getTime()/86400000);
      newDate.setTime(86400000*day + 86399000 + offset);
      this.props.setStatDateSelected(1,newDate);
    }
  }

  drawChart(receivedProps){

    let minUnix = Math.round(this.props.statDateSelected[0].getTime()/1000);
    let maxUnix = Math.round(this.props.statDateSelected[1].getTime()/1000);


    var ctx = document.getElementById("myChart");
    let minimumValue = [0,0];
    let maximumValue = [0,0];

    let data0=[];
    let decimalsRounded = statInfo("decimalsRounded", this.props.statIndexSelected[0]);

    let fisrtReport = true;
    Object.keys(receivedProps.reports).map((index) => {
      if (receivedProps.reports[index].unixtime >= minUnix && receivedProps.reports[index].unixtime <= maxUnix){
        let itemUnix = receivedProps.reports[index].unixtime;
        let itemValue = Math.round(receivedProps.reports[index][statInfo("name",this.props.statIndexSelected[0])]*(10**decimalsRounded))/(10**decimalsRounded);
        data0.push({x: itemUnix*1000, y: itemValue});
        if (minimumValue[0]>itemValue || fisrtReport === true){minimumValue[0]=itemValue;}
        if (maximumValue[0]<itemValue || fisrtReport === true){maximumValue[0]=itemValue;}
        fisrtReport = false;
      }
    });

    let data1=[];
    decimalsRounded = statInfo("decimalsRounded", this.props.statIndexSelected[1]);

    fisrtReport = true;
    Object.keys(receivedProps.reports).map((index) => {
      if (receivedProps.reports[index].unixtime >= minUnix && receivedProps.reports[index].unixtime <= maxUnix){
        let itemUnix = receivedProps.reports[index].unixtime;
        let itemValue = Math.round(receivedProps.reports[index][statInfo("name",this.props.statIndexSelected[1])]*(10**decimalsRounded))/(10**decimalsRounded);
        data1.push({x: itemUnix*1000, y: itemValue});
        if (minimumValue[1]>itemValue || fisrtReport === true){minimumValue[1]=itemValue;}
        if (maximumValue[1]<itemValue || fisrtReport === true){maximumValue[1]=itemValue;}
        fisrtReport = false;
      }
    });

    let datasets = [];
    datasets[0] = {};
    datasets[0].label = statInfo("label",this.props.statIndexSelected[0]);
    datasets[0].fill = false;
    datasets[0].backgroundColor = 'rgba(0, 255, 0, 1)';
    datasets[0].yAxisID = "A";
    datasets[0].data = data0;

    if (this.props.statIndexSelected[1] !== -1){
      datasets[1] = {};
      datasets[1].label = statInfo("label",this.props.statIndexSelected[1]);
      datasets[1].fill = false;
      datasets[1].backgroundColor = 'rgba(0, 0, 255, 1)';
      datasets[1].yAxisID = "B";
      datasets[1].data = data1;
    }



    let verticalTicks = [];
    verticalTicks[0] = JSON.parse(JSON.stringify(statInfo("ticks",this.props.statIndexSelected[0])));
    if (this.props.statIndexSelected[0] === 11){
      debugger;
    }
    if (minimumValue[0] < verticalTicks[0].min){
      verticalTicks[0].min = minimumValue[0];
    }
    if (maximumValue[0] > verticalTicks[0].max){
      verticalTicks[0].max = maximumValue[0];
    }
    verticalTicks[1] = JSON.parse(JSON.stringify(statInfo("ticks",this.props.statIndexSelected[1])));
    if (minimumValue[1] < verticalTicks[1].min){
      verticalTicks[1].min = minimumValue[1];
    }
    if (maximumValue[1] > verticalTicks[1].max){
      verticalTicks[1].max = maximumValue[1];
    }

    let yAxis = [];
    yAxis[0] = {};
    yAxis[0].id = "A";
    yAxis[0].type = "linear";
    yAxis[0].position = "left";
    yAxis[0].ticks = verticalTicks[0];

    if (this.props.statIndexSelected[1] !== -1){
      yAxis[1] = {};
      yAxis[1].id = "B";
      yAxis[1].type = "linear";
      yAxis[1].position = "right";
      yAxis[1].ticks = verticalTicks[1];
    }

    if (this.chart !== null){this.chart.destroy();}

    this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                    quarter: 'MMM D'
                }
              },
              distribution: 'linear'
            }],
            yAxes: yAxis,
          },
        }
      });

  }
  render(){

    return (
      <div className="statisticsPanel">
        <div className="statsUp">
          <div className="statField">
            <div style={{display:"inline-block", marginRight:"10px"}}>
              Dato 1:
            </div>
            <Dropdown
                style={{color: "rgb(0,255,0)"}}
                selection
                value={this.props.statIndexSelected[0]}
                options={statInfo("options",0)}
                onChange={(e,data) => this.props.setStatIndexSelected(0,data.value)}
            />
          </div>
          <div className="statField">
            <div style={{display:"inline-block", marginRight:"10px"}}>
              Dato 2:
            </div>
            <Dropdown
                style={{color: "rgb(0,0,255)"}}
                selection
                value={this.props.statIndexSelected[1]}
                options={statInfo("options",1)}
                onChange={(e,data) => this.props.setStatIndexSelected(1,data.value)}
            />
          </div>
          <div className="statField">
            <div style={{display:"inline-block", marginRight:"10px"}}>
              Fecha min.:
            </div>
            <DatePicker
              style={{margin:"5px"}}
              selected={this.props.statDateSelected[0]}
              onChange={(date) => this.handleChangeDate(0,date)}
              dateFormat="dd/MM/yyyy"
              locale="es"
              minDate={this.minDate}
              maxDate={this.maxDate}
              showYearDropdown
            />
          </div>
          <div className="statField">
            <div style={{display:"inline-block", marginRight:"10px"}}>
              Fecha máx.:
            </div>
            <DatePicker
              style={{margin:"5px"}}
              selected={this.props.statDateSelected[1]}
              onChange={(date) => this.handleChangeDate(1,date)}
              dateFormat="dd/MM/yyyy"
              locale="es"
              minDate={this.minDate}
              maxDate={this.maxDate}
              showYearDropdown
            />
          </div>
        </div>
        <div className="statsDown">
          <canvas className="plotCanvas" id="myChart"></canvas>
        </div>
      </div>
    );
  }

}

export default Statistics;
