/* @flow */
import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import './mapDisplay.css';
import {googleLoadMap, googleUpdateMap, googleMoveMarker} from '../utils/googleMaps';
import {getDateTimeTextFromUnix,getFirstAndLastUnixOfDay} from '../utils/functions';

export default class MapDisplay extends Component {
  constructor(props) {
    super(props);

    this.loadMap = this.loadMap.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    //this.moveLocation = this.moveLocation.bind(this);

    this.googleObject={"map": null, "path": [], "marker": null, "initialCenter": null, "initialZoom": null};
    this.dayPath=[];
  }


  componentDidMount() {
    //console.log("MapDisplay montado");
    this.loadMap();
    this.updateMap(this.props);
    this.updateLocation(this.props);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("MapDisplay WillReceiveProps: " + this.props.updateMapIndex);
    if (nextProps.updateMapIndex !== this.props.updateMapIndex){
      //console.log("updateMap()");
      this.updateMap(nextProps);
    }

    if (nextProps.updateMapIndex !== this.props.updateMapIndex || nextProps.updateLocationIndex !== this.props.updateLocationIndex) {
        //console.log("updateLocation()");
        this.updateLocation(nextProps);
    }
  }

  loadMap(){
    //console.log("loadMap()");
    let infoTextString = "Sin GPS";
    if (this.props.dayReportsIndex !== -1 && this.props.dayReports.length>0){
      infoTextString = getDateTimeTextFromUnix(this.props.dayReports[this.props.dayReportsIndex].unixtime);
    };
    let firstZoomText;
    if (this.props.zoom === "general"){
      firstZoomText = "Zoom general";
    }else if (this.props.zoom === "cerca"){
      firstZoomText = "Zoom cerca";
    }else if (this.props.zoom === "perso"){
      firstZoomText = "Zoom perso.";
    }
    this.googleObject = googleLoadMap(this.props.setDayReportsIndex,infoTextString,this.props.setZoom,firstZoomText,this.props.zoomListenerCallback);
    this.props.setGoogleMapObject(this.googleObject.map);
  }

  updateMap(receivedProps){
    googleUpdateMap(this.googleObject, receivedProps.dayReports, receivedProps.blockZoomListenter,receivedProps.setDayReportsIndex);
  }

  updateLocation(receivedProps){
    if (receivedProps.dayReportsIndex != -1){
      let infoTextString = getDateTimeTextFromUnix(receivedProps.dayReports[receivedProps.dayReportsIndex].unixtime);
      googleMoveMarker(this.googleObject,receivedProps.dayReports[receivedProps.dayReportsIndex], receivedProps.zoom,infoTextString,receivedProps.blockZoomListenter);
    }else{
      let infoTextString = "Sin GPS";
      googleMoveMarker(this.googleObject,null,"general",infoTextString,this.props.blockZoomListenter);
    }
  }



  render() {
    return (
          <div id="map" style={{width: "100%",height: "100%"}}/>
    );
  }
}
