import React, {Component} from 'react';
import {Container , Segment, Grid, Label , Button , Icon} from 'semantic-ui-react';
import DatePicker,{ registerLocale, setDefaultLocale, getDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import {getDayFromUnix} from '../utils/functions.js';

export default class DateSelector extends Component {
  constructor(props) {
    super(props);
    this.state={

    }
    this.handleChange = this.handleChange.bind(this);
    registerLocale('es', es);
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }


  handleChange(date) {
    this.props.setDateSelectected(date);
  }


  render() {

    let isTodaySelected = false;
    let today = new Date();
    if (getDayFromUnix(Math.floor(this.props.dateSelected.getTime()/1000)) === getDayFromUnix(Math.floor(today.getTime()/1000))){
      isTodaySelected = true;
    }

    return (
        <div className="dateDisplay">
          <Button
            style={{margin:"5px"}}
            circular
            icon='minus'
            size='mini'
            onClick={() => this.props.setDateSelectected("minus")}
          />
          <Button
            style={{margin:"5px"}}
            disabled={isTodaySelected}
            circular
            icon='plus'
            size='mini'
            onClick={() => this.props.setDateSelectected("plus")}
          />
          <DatePicker
            style={{margin:"5px"}}
            selected={this.props.dateSelected}
            onChange={this.handleChange}
            dateFormat="dd/MM/yyyy"
            locale="es"
            maxDate={today}
            showYearDropdown
            highlightDates={[today]}
          />
          <Button
            style={{margin:"5px"}}
            active={isTodaySelected}
            onClick={() => this.props.setDateSelectected("today")}
            size='mini'
            color={isTodaySelected ? 'blue' : null}
          >
            Hoy
          </Button>

        </div>
    );
  }
}
