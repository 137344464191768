import React, { Component  } from 'react';
import {Dimmer, Loader, Image, Segment, Container, Grid, TransitionablePortal} from '../node_modules/semantic-ui-react';
import logo from './logo.svg';
import './App.css';
import Header from './Header/';
import HeaderMobile from './HeaderMobile/';
import MainPanel from './MainPanel/';
import Statistics from './Statistics/';
//import Gallery from './Gallery/';
import Links from './Links/';

import {getFirstAndLastUnixOfDay,getDayFromUnix, statInfo} from './utils/functions.js';


export default class App extends Component {

  constructor(props) {
    super(props);

    this.firstMinDate = new Date();
    if (window.screen.width > 1200){
      this.firstMinDate.setTime(this.firstMinDate.getTime()-7*86400000)
    }else{
      this.firstMinDate.setTime(this.firstMinDate.getTime()-3*86400000)
    }
    this.firstMaxtDate = new Date();

    this.state = {
      waitingResponse: true,
      reports: [],
      dayReports: [],
      dayReportsIndex: -1,
      componentViewed: "MainPanel",
      lastRefreshUnix: 0,
      dateSelected: new Date(),
      updateMapIndex: 0,
      updateLocationIndex: 0,
      zoom: "general",
      statIndexSelected: [0,1],
      statDateSelected: [this.firstMinDate,this.firstMaxtDate],
    };

    this.components=["MainPanel"];
    this.prevComponentViewed = "MainPanel";

    this.setComponentViewed=this.setComponentViewed.bind(this);
    this.makeRequest=this.makeRequest.bind(this);
    this.receiveRequest=this.receiveRequest.bind(this);
    this.checkFetch = this.checkFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.setFetchBlocked = this.setFetchBlocked.bind(this);
    this.setDateSelectected = this.setDateSelectected.bind(this);
    this.prepareDayReports = this.prepareDayReports.bind(this);
    this.processReceivedPackage = this.processReceivedPackage.bind(this);
    this.setDayReportsIndex = this.setDayReportsIndex.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.zoomListenerCallback = this.zoomListenerCallback.bind(this);
    this.blockZoomListenter = this.blockZoomListenter.bind(this);
    this.setStatIndexSelected = this.setStatIndexSelected.bind(this);
    this.setStatDateSelected = this.setStatDateSelected.bind(this);


//*****************************************************************************************************************
    this.offline=false;
    //online params:
    this.apiURL = 'https://api.gaviota.cosasdejuan.es/gaviotaApi.php';
//*****************************************************************************************************************
    this.fetchPeriod = 59;   //Cada cuantos segundos solicita refresco de datos
    this.checkFetchPeriod = 30; //Cada cuantos segundos comprueba si debe refrescar
//*****************************************************************************************************************
    this.fetchBlocked = false;
    this.onGoingRequest = false;

    this.zoomListenerBlocked = false;

  }


  componentDidMount(){
    let obj = {}
    obj["reqType"]="fetch";
    this.makeRequest(obj);
    window.setInterval(this.checkFetch,this.checkFetchPeriod * 1000);    //Comprueba si hay que refrescar cada X segundos
  }

  checkFetch(){
    let unixNow = new Date();
    unixNow = Math.floor(unixNow.getTime()/1000);
    if (this.onGoingRequest === false && this.fetchBlocked === false && unixNow - this.state.lastRefreshUnix >= this.fetchPeriod){     //Solicita refresco si han pasado X segundos desde el ultimo refresco
      let obj = {}
      obj["reqType"]="fetch";
      this.makeRequest(obj);
    }
  }

  fetch(){
    console.log("fetching");
    let obj = {}
    obj["reqType"]="fetch";    
    this.makeRequest(obj);
  }

  setFetchBlocked(blocked){
    this.fetchBlocked = blocked;
    if (blocked === false) {
      this.checkFetch()
    }
  }

  setComponentViewed(newComponentViewed){

    if (newComponentViewed === "minus" || newComponentViewed === "plus"){
      let index = this.components.indexOf(this.state.componentViewed);
      if (newComponentViewed==="minus"){
        index--;
        if (index<0){index=0;}
      }else if (newComponentViewed==="plus"){
        index++;
        if (this.components[index] === undefined){index--;}
      }
      newComponentViewed=this.components[index];
    }else if (newComponentViewed === "back"){
      newComponentViewed=this.prevComponentViewed;
    }

    this.prevComponentViewed = this.state.componentViewed;
    this.setState({componentViewed: newComponentViewed});
  }

  makeRequest(obj){
    if (this.offline === false){
      var componentReference = this;

      var http = new XMLHttpRequest();

      let payload = "payload=" + JSON.stringify(obj);
  		http.open('POST', this.apiURL, true);
  		http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      //http.setRequestHeader('Accept-Encoding', 'gzip'); //El browser no permite añadirlo: Refused to set unsafe header "Accept-Encoding"
  		http.onreadystatechange = function() {  //Call a function when the state changes.
  			if(http.readyState === 4 && http.status === 200) {
          var resp = JSON.parse(http.responseText);
          componentReference.receiveRequest(resp);
        }
  		}

      http.send(payload);

    }else if (this.offline===true){
      this.offlineServer = require('./utils/offlineServer');
      const fakeResp = this.offlineServer.makeRequestOffline(obj);
      setTimeout(() => this.receiveRequest(fakeResp),1000);
    }

    this.onGoingRequest = true;
  }

  receiveRequest(resp){
    let nowAux = new Date();
    resp = this.processReceivedPackage(resp);
    this.setState({
      reports: resp.reports,
      lastRefreshUnix: Math.floor(nowAux.getTime()/1000),
    });
    let newDateSelected = new Date();
    newDateSelected.setTime(resp.reports[resp.reports.length-1].unixtime*1000);
    this.setDateSelectected(newDateSelected);
  }

  processReceivedPackage(resp){
    for (let i=0;i<resp.reports.length;i++){
      resp.reports[i].lat = resp.reports[i].gpslatdeg + resp.reports[i].gpslatmin/60;
      if (resp.reports[i].gpslatchar === "S"){
        resp.reports[i].lat *= -1;
      }
      resp.reports[i].lng = resp.reports[i].gpslondeg + resp.reports[i].gpslonmin/60;
      if (resp.reports[i].gpslonchar === "W"){
        resp.reports[i].lng *= -1;
      }
    }
    return resp;
  }

  setDateSelectected(newDateSelected){
    if (newDateSelected === "minus"){
      newDateSelected = new Date();
      newDateSelected.setTime(this.state.dateSelected.getTime()-86400000);
    }else if (newDateSelected === "plus"){
      newDateSelected = new Date();
      newDateSelected.setTime(this.state.dateSelected.getTime()+86400000);
    }else if (newDateSelected === "today"){
      newDateSelected = new Date();
    }

    let newDayReports = this.prepareDayReports(newDateSelected);
    this.setState({
      dateSelected:newDateSelected,
      zoom:"general",
      dayReports: newDayReports,
      dayReportsIndex: newDayReports.length-1,
      updateMapIndex: this.state.updateMapIndex+1,
    });
  }

  setDayReportsIndex(newDayReportsIndex){
    if (newDayReportsIndex === "minus"){
      newDayReportsIndex = this.state.dayReportsIndex - 1;
    }else if (newDayReportsIndex === "plus"){
      newDayReportsIndex = this.state.dayReportsIndex + 1;
    }

    if (newDayReportsIndex < 0){
      this.setDateSelectected("minus");
    }else if (newDayReportsIndex > this.state.dayReports.length - 1){
      let isTodaySelected = false;
      let today = new Date();
      if (getDayFromUnix(Math.floor(this.state.dateSelected.getTime()/1000)) === getDayFromUnix(Math.floor(today.getTime()/1000))){
        isTodaySelected = true;
      }
      if (isTodaySelected !== true){
        this.setDateSelectected("plus");
        this.setState({dayReportsIndex: 0, updateLocationIndex: this.state.updateLocationIndex+1});
      }
    }else{
      if (this.state.dayReports[newDayReportsIndex] !== undefined){
        this.setState({dayReportsIndex: newDayReportsIndex, updateLocationIndex: this.state.updateLocationIndex+1});
      }
    }


  }

  prepareDayReports(newDateSelected){
    let limits = getFirstAndLastUnixOfDay(Math.floor(newDateSelected.getTime()/1000));
    let dayReports = [];
    for (let i=0;i<this.state.reports.length;i++){
      if (this.state.reports[i].unixtime>=limits[0] && this.state.reports[i].unixtime < limits[1]){
        dayReports.push(this.state.reports[i]);
      }
    }
    return dayReports;
  }

  setZoom(newValue){
      if (newValue === "toggle"){
        if (window.screen.width>1200){
          if (this.state.zoom === "general"){
            newValue="cerca";
          }else if (this.state.zoom === "cerca"){
            newValue="general";
          }else if (this.state.zoom === "perso"){
            newValue="general";
          }else{
            newValue="general";
          }
        }else{
          newValue="perso";
        }
      }

      if (newValue !== "ignore"){
        this.setState({
          zoom: newValue,
          updateLocationIndex: this.state.updateLocationIndex+1,
        });
      }

  }

  zoomListenerCallback(){
    if (this.zoomListenerBlocked === false){
      if (this.state.zoom !== "perso"){
        this.setZoom("perso");
      }
    }else{
      //console.log("Zoom modificado por código");
      this.zoomListenerBlocked = false;
    }
  }

  blockZoomListenter(){
    this.zoomListenerBlocked = true;
  }

  setStatIndexSelected(statNumber,newStatIndex){
    if (newStatIndex==="minus"){
      newStatIndex = this.state.statIndexSelected[statNumber]-1;
    }else if (newStatIndex === "plus"){
      newStatIndex = this.state.statIndexSelected[statNumber]+1;
    }

    if (statInfo("name",newStatIndex) !== "outOfRange" || (newStatIndex === -1 && statNumber === 1)){
      let newStatIndexSelected = this.state.statIndexSelected;
      newStatIndexSelected[statNumber] = newStatIndex;
      this.setState({statIndexSelected: newStatIndexSelected});
    }
  }

  setStatDateSelected(dateNumber,newDate){
    let newStatDateSelected = this.state.statDateSelected;
    newStatDateSelected[dateNumber] = newDate;
    this.setState({statDateSelected: newStatDateSelected});
  }

  render(){

      if (this.state.reports.length === 0){
          return (
            <div className='loader'>
                <Dimmer active inverted>
                  <Loader size='big'>Cargando...</Loader>
                </Dimmer>
            </div>
          );
      }

      return(
        <div className="App">
          <div className="header removeWhenMobile removeWhenTablet">
            <Header
              componentViewed={this.state.componentViewed}
              setComponentViewed={this.setComponentViewed}
              lastRefreshUnix={this.state.lastRefreshUnix}
            />
          </div>
          <div className="header removeWhenLaptop">
            <HeaderMobile
              componentViewed={this.state.componentViewed}
              setComponentViewed={this.setComponentViewed}
              lastRefreshUnix={this.state.lastRefreshUnix}
              fetch={this.fetch}
            />
          </div>

          <div className="notHeader">

            {this.state.componentViewed==="MainPanel" && this.state.dayReportsIndex !== -1 &&
              <MainPanel
                dayReports={this.state.dayReports}
                dayReportsIndex={this.state.dayReportsIndex}
                locationIndexSelected = {this.state.locationIndexSelected}
                setLocationIndexSelected = {this.setLocationIndexSelected}
                dateSelected = {this.state.dateSelected}
                setDateSelectected = {this.setDateSelectected}
                setDayReportsIndex = {this.setDayReportsIndex}
                updateMapIndex = {this.state.updateMapIndex}
                updateLocationIndex = {this.state.updateLocationIndex}
                zoom = {this.state.zoom}
                setZoom={this.setZoom}
                zoomListenerCallback = {this.zoomListenerCallback}
                blockZoomListenter = {this.blockZoomListenter}
                offline = {this.offline}
              />
            }

            {this.state.componentViewed==="Statistics" &&
              <Statistics
                reports={this.state.reports}
                statIndexSelected = {this.state.statIndexSelected}
                setStatIndexSelected = {this.setStatIndexSelected}
                statDateSelected = {this.state.statDateSelected}
                setStatDateSelected = {this.setStatDateSelected}
              />
            }
            {this.state.componentViewed==="Links" &&
              <Links

              />
            }
          </div>
        </div>
      );
  }
}
