import React, { Component } from 'react';
import { Container, Grid ,  Dimmer, Loader, Image, Segment, Divider} from 'semantic-ui-react';
import DateSelector from './DateSelector';
import DataDisplay from './DataDisplay';
import MapDisplay from './MapDisplay';

export default class MainPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      googleMapObject: {},
      showActivityComp: false,
      reportModal:{
                show: false,
                deviceId: null,

              },
    };

    this.reloadState = this.reloadState.bind(this);
    this.setGoogleMapObject = this.setGoogleMapObject.bind(this);

  }

  componentWillMount(){
    this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    switch(event.keyCode) {
        case 38:
            //up key
            break;
        case 40:
            //down key
            break;
        case 37:
            this.props.setDayReportsIndex("minus");
            break;
        case 39:
            this.props.setDayReportsIndex("plus");
            break;
        default:
            break;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidMount(){
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps !== this.props){
      this.reloadState(nextProps);
      //console.log("reload ViewDevices: "+Math.random());
    }
  }

  reloadState(receivedProps){

  }

  setGoogleMapObject(newGoogleMapObject){
    this.setState({googleMapObject: newGoogleMapObject,});
  }

  render() {

    return (
      <div className="MainPanel">

        <div className="leftPanel">
          <DataDisplay
            dateSelected = {this.props.dateSelected}
            setDateSelectected={this.props.setDateSelectected}
            dayReport = {this.props.dayReports[this.props.dayReportsIndex]}
            dayReportsIndex = {this.props.dayReportsIndex}
            setDayReportsIndex = {this.props.setDayReportsIndex}
            dayReportsLength = {this.props.dayReports.length}
          />
        </div>
        {this.props.offline === false &&
          <div className="rightPanel">
            <MapDisplay
              dayReports = {this.props.dayReports}
              dayReportsIndex = {this.props.dayReportsIndex}
              setDayReportsIndex = {this.props.setDayReportsIndex}
              setGoogleMapObject = {this.setGoogleMapObject}
              updateMapIndex =  {this.props.updateMapIndex}
              updateLocationIndex = {this.props.updateLocationIndex}
              zoom = {this.props.zoom}
              setZoom = {this.props.setZoom}
              zoomListenerCallback = {this.props.zoomListenerCallback}
              blockZoomListenter = {this.props.blockZoomListenter}
            />
          </div>
        }

      </div>
    );
  }
}

/*

*/
