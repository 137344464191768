
function makeRequestOffline(obj){
	let fakeResp = {};
	if (obj["reqType"]==="fetch"){
		var myModule;
		myModule = require('./mockDataGaviota');

		fakeResp =JSON.parse(JSON.stringify(myModule.mockDataGaviota));

		return fakeResp;
	}
}

exports.makeRequestOffline = makeRequestOffline;
