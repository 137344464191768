import React, { Component, Fragment , Prompt } from 'react';
import { Menu , Dropdown , Icon, Label} from '../../node_modules/semantic-ui-react';
import {headerInfo} from '../utils/functions.js';
import './styles.css';

import {getTimeTextFromUnix} from '../utils/functions.js';

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }


  handleItemClick(e,data){
    this.props.setComponentViewed(data.name,null);
  };

  render() {
    let refreshText = "Actualizado a las " + getTimeTextFromUnix(this.props.lastRefreshUnix);

    return (
      <div>
        <Menu inverted color="red" className="main-menu" style={{float:"left", width: "30%", margin:"0px"}}>
          <Menu.Item
            name="MainPanel"
            active={this.props.componentViewed === 'MainPanel'}
            onClick={(e,data) => this.handleItemClick(e,data)}
            className="menu-icon"
          >
            <Icon name={headerInfo("icon","MainPanel")}/>
            {headerInfo("name","MainPanel")}
          </Menu.Item>
          <Menu.Item
            name="Statistics"
            active={this.props.componentViewed === 'Statistics'}
            onClick={(e,data) => this.handleItemClick(e,data)}
            className="menu-icon"
          >
            <Icon name={headerInfo("icon","Statistics")}/>
            {headerInfo("name","Statistics")}
          </Menu.Item>          
          <Menu.Item
            name="Links"
            active={this.props.componentViewed === 'Links'}
            onClick={(e,data) => this.handleItemClick(e,data)}
            className="menu-icon"
          >
            <Icon name={headerInfo("icon","Links")}/>
            {headerInfo("name","Links")}
          </Menu.Item>
        </Menu>
        <div style={{float:"left", height: "40px", width:"50%",background:"#db2828"}}>
          <p style={{textAlign:"center", padding:"10px", color:"white", fontSize: "18px", fontWeight: "900", fontStyle:"italic", fontFamily:"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"}}>
            Velero Aventura - Sistema Gaviota
          </p>
        </div>
        <div style={{float:"left", height: "40px", width:"20%",background:"#db2828"}}>
          <p style={{textAlign:"center", padding:"13px", color:"white", fontSize: "12px", fontWeight: "900", fontFamily:"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"}}>
            {refreshText}
          </p>
        </div>
      </div>
    );
  }
}
