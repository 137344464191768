const startIcon = {
  path: 'M -2,0 0,-2 2,0 0,2 z',
  strokeColor: '#F00',
  fillColor: '#F00',
  fillOpacity: 1,
};

const endIcon = {
  path: 'M -2,-2 2,2 M 2,-2 -2,2',
  strokeColor: '#292',
  strokeWeight: 4,
};

export function createMap(map,position,marker) {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {

    const zoomLevel=13;

    var newMap = new window.google.maps.Map(document.getElementById(map), {
      zoom: zoomLevel,
      center: position,
      mapTypeId: 'hybrid',
    });

    marker = new window.google.maps.Marker({ position: position, map: newMap });

    return newMap;
  }
}

function MoveControl(controlDiv, map, setDayReportsIndex, infoTextString, setZoom, firstZoomText) {

  controlDiv.style.clear = 'both';

  var zoomUI = document.createElement('div');
  zoomUI.className = "removeWhenMobile removeWhenTablet";
  zoomUI.id = 'zoomUI';
  zoomUI.title = 'Nivel de zoom al navegar entre puntos';
  controlDiv.appendChild(zoomUI);
  let zoomText = document.createElement('div');
  zoomText.id = 'zoomText';
  zoomText.innerHTML = firstZoomText;
  zoomUI.appendChild(zoomText);

  var decUI = document.createElement('div');
  decUI.className = "removeWhenMobile removeWhenTablet";
  decUI.id = 'decUI';
  decUI.title = 'Cursor izquierda';
  controlDiv.appendChild(decUI);
  var decText = document.createElement('div');
  decText.id = 'decText';
  decText.innerHTML = '<';
  decUI.appendChild(decText);

  var incUI = document.createElement('div');
  incUI.className = "removeWhenMobile removeWhenTablet";
  incUI.id = 'incUI';
  incUI.title = 'Cursor derecha';
  controlDiv.appendChild(incUI);
  var incText = document.createElement('div');
  incText.id = 'incText';
  incText.innerHTML = '>';
  incUI.appendChild(incText);

  var infoUI = document.createElement('div');
  infoUI.id = 'infoUI';
  infoUI.title = 'Fecha y hora';
  controlDiv.appendChild(infoUI);
  let infoText = document.createElement('div');
  infoText.id = 'infoText';
  infoText.innerHTML = infoTextString;
  infoUI.appendChild(infoText);

  decUI.addEventListener('click', function() {
    setDayReportsIndex("minus");
  });
  incUI.addEventListener('click', function() {
    setDayReportsIndex("plus");
  });
  zoomUI.addEventListener('click', function() {
    setZoom("toggle");
  });
}

function MoveLeftMobile(controlDiv, map, setDayReportsIndex) {
  controlDiv.style.clear = 'both';

  var decUI = document.createElement('div');
  decUI.className = "removeWhenLaptop";
  decUI.id = 'decUI';
  decUI.style["height"] = '50px';
  decUI.style["width"] = '50px';
  decUI.style["margin-left"] = '0px';
  decUI.title = 'Cursor izquierda';
  controlDiv.appendChild(decUI);
  var decText = document.createElement('div');
  decText.id = 'decText';
  decText.innerHTML = '<';
  decText.style["font-size"] = "30px";
  decText.style["line-height"] = "45px";
  decUI.appendChild(decText);

  decUI.addEventListener('click', function() {
    setDayReportsIndex("minus");
  });
}

function MoveRightMobile(controlDiv, map, setDayReportsIndex) {
  controlDiv.style.clear = 'both';

  var incUI = document.createElement('div');
  incUI.className = "removeWhenLaptop";
  incUI.id = 'incUI';
  incUI.style["height"] = '50px';
  incUI.style["width"] = '50px';
  incUI.title = 'Cursor derecha';
  controlDiv.appendChild(incUI);
  var incText = document.createElement('div');
  incText.id = 'incText';
  incText.innerHTML = '>';
  incText.style["font-size"] = "30px";
  incText.style["line-height"] = "45px";
  incUI.appendChild(incText);

  incUI.addEventListener('click', function() {
    setDayReportsIndex("plus");
  });
}

export function googleLoadMap(setDayReportsIndex, infoTextString, setZoom, firstZoomText,zoomListenerCallback){
  let initialCenter = new window.google.maps.LatLng(39.5, -4.5);
  let initialZoom = 5;
  //creo un map
  let map = new window.google.maps.Map(document.getElementById("map"), {
    zoom: initialZoom,
    center: initialCenter,
    mapTypeId: 'hybrid',
    mapTypeControl: false,
  });

  //creo un control de posicion para el map
  let moveControlDiv = document.createElement('div');
  let moveControl = new MoveControl(moveControlDiv, map, setDayReportsIndex, infoTextString, setZoom, firstZoomText);
  moveControlDiv.index = 1;
  moveControlDiv.style['padding-top'] = '10px';
  map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(moveControlDiv);

  let moveLeftMobileDiv = document.createElement('div');
  let moveLeftMobile = MoveLeftMobile(moveLeftMobileDiv, map, setDayReportsIndex);
  moveLeftMobileDiv.index = 1;
  moveLeftMobileDiv.style['padding-left'] = '10px';
  map.controls[window.google.maps.ControlPosition.LEFT_CENTER].push(moveLeftMobileDiv);

  let moveRightMobileDiv = document.createElement('div');
  let moveRightMobile = MoveRightMobile(moveRightMobileDiv, map, setDayReportsIndex);
  moveRightMobileDiv.index = 1;
  moveRightMobileDiv.style['padding-right'] = '10px';
  map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(moveRightMobileDiv);

  //Creo un path nulo
  let path = new window.google.maps.Polyline({
    path: [],
    geodesic: true,
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2,
    icons: [
      {
        icon: startIcon,
        offset: '0%',
      },
      {
        icon: endIcon,
        offset: '100%',
      },
    ],
  });

  //Creo un marker nulo
  function pinSymbol(color) {
      return {
          path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
          fillColor: color,
          fillOpacity: 0.6,
          strokeColor: '#000',
          strokeWeight: 2,
          scale: 1,
     };
  }

  //Creo un un array nulo para positionIcons
  let positionIcons = [];

  let marker = new window.google.maps.Marker({
    position: null,
    icon: pinSymbol("red"),
  });

  window.google.maps.event.addListener(map, 'zoom_changed',zoomListenerCallback);

  return {"map": map, "path": path, "marker": marker, "initialCenter": initialCenter, "initialZoom": initialZoom, "positionIcons": positionIcons};
}

export function googleUpdateMap(googleObject,dayPath,blockZoomListenter,setDayReportsIndex){
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    //Asigno el nuevo path
    googleObject.path.setMap(null);
    googleObject.path = new window.google.maps.Polyline({
      path: dayPath,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      icons: [
        {
          icon: startIcon,
          offset: '0%',
        },
        {
          icon: endIcon,
          offset: '100%',
        },
      ],
    });
    googleObject.path.setMap(googleObject.map);

    //Creo iconos en vertices
    function circulitos(color) {
        return {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: color,
            fillOpacity: 0.6,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 3,
       };
    }

    for (let i=0;i<googleObject.positionIcons.length;i++){
      googleObject.positionIcons[i].setMap(null);
    }

    googleObject.positionIcons = [];
    for (let i=0;i<dayPath.length;i++){
      googleObject.positionIcons[i] = new window.google.maps.Marker({
        position: {lat: dayPath[i].lat , lng: dayPath[i].lng},
        icon: circulitos("blue"),
      });
      googleObject.positionIcons[i].addListener("click",() => setDayReportsIndex(i));
      googleObject.positionIcons[i].setMap(googleObject.map);
    }


    //Centro y asigno zoom al mapa
    var latMean= 39.5;
    var lngMean= -4.5;
    googleObject.initialZoom=5;
    if (dayPath.length>0){
      var latUpper=-90;
      var latLower=90;
      var lngUpper=-180;
      var lngLower=180;
      for (var i=0;i<dayPath.length;i++){
        if (latUpper<dayPath[i].lat){latUpper=dayPath[i].lat;}
        if (latLower>dayPath[i].lat){latLower=dayPath[i].lat;}
        if (lngUpper<dayPath[i].lng){lngUpper=dayPath[i].lng;}
        if (lngLower>dayPath[i].lng){lngLower=dayPath[i].lng;}
      }

      var latMean=(latUpper+latLower)/2;
      var lngMean=(lngUpper+lngLower)/2;
      //const centerOfMap = { "lat": latMean, "lng": lngMean};

      var latRange=(latUpper-latLower);
      var lngRange=(lngUpper-lngLower);
      var maxRange=latRange;
      if (lngRange>maxRange){maxRange=lngRange;}
      if (maxRange>64){googleObject.initialZoom=2;}
      else if (maxRange>32){googleObject.initialZoom=3;}
      else if (maxRange>16){googleObject.initialZoom=4;}
      else if (maxRange>8){googleObject.initialZoom=5;}
      else if (maxRange>4){googleObject.initialZoom=6;}
      else if (maxRange>2){googleObject.initialZoom=7;}
      else if (maxRange>1){googleObject.initialZoom=8;}
      else if (maxRange>0.5){googleObject.initialZoom=9;}
      else if (maxRange>0.25){googleObject.initialZoom=10;}
      else if (maxRange>0.13){googleObject.initialZoom=11;}
      else if (maxRange>0.07){googleObject.initialZoom=12;}
      else if (maxRange>0.033){googleObject.initialZoom=13;}
      else if (maxRange>0.02){googleObject.initialZoom=14;}
      else if (maxRange>0.01){googleObject.initialZoom=15;}
      else {googleObject.initialZoom=15;}
    }

    googleObject.initialCenter = new window.google.maps.LatLng(latMean, lngMean);
    googleObject.map.setCenter(googleObject.initialCenter);
    blockZoomListenter();
    googleObject.map.setZoom(googleObject.initialZoom);      // This will trigger a zoom_changed on the map
    console.log("maxRange: "+maxRange);
    console.log("zoom: "+googleObject.initialZoom);
  }
}

export function googleMoveMarker(googleObject,position,zoom,infoTextString,blockZoomListenter){
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    function pinSymbol(color) {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: color,
            fillOpacity: 0.6,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1,
       };
    }

    googleObject.marker.setMap(null);

    if (position !== null){
      googleObject.marker = new window.google.maps.Marker({
        position: {lat: position.lat , lng: position.lng},
        icon: pinSymbol("red"),
        title: "Velocidad: " + position.speed + "km/h",
      });
      googleObject.marker.setMap(googleObject.map);
    }

    let infoText = document.getElementById("infoText");
    if (infoText !== null){
      infoText.innerHTML = infoTextString;
    }

    let zoomString = "¿?";
    if (zoom === "general"){zoomString = "Zoom general";}
    if (zoom === "cerca"){zoomString = "Zoom cerca";}
    if (zoom === "perso"){zoomString = "Zoom perso";}
    let zoomText = document.getElementById("zoomText");
    if (zoomText !== null){
      zoomText.innerHTML = zoomString;
    }

    if (position !== null && zoom === "cerca"){
      googleObject.map.setCenter(new window.google.maps.LatLng(position.lat, position.lng));
      blockZoomListenter();
      googleObject.map.setZoom(15);
    }else if (position !== null && zoom === "perso"){
      googleObject.map.setCenter(new window.google.maps.LatLng(position.lat, position.lng));
    }else{
      googleObject.map.setCenter(googleObject.initialCenter);
      blockZoomListenter();
      googleObject.map.setZoom(googleObject.initialZoom);
    }
  }
}
