import React, {Component} from 'react';
import {Container , Segment, Grid, Label , Button , Icon, Input} from 'semantic-ui-react';
import {getDayFromUnix, getDateTextFromUnix, getTimeTextFromUnix, roseta, estriborBabor, signal, voltage, puerta, sentina, sistema, alarma} from '../utils/functions.js';
import DatePicker,{ registerLocale, setDefaultLocale, getDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


export default class DataDisplay extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
    registerLocale('es', es);
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  render() {

    let isTodaySelected = false;
    let today = new Date();
    if (getDayFromUnix(Math.floor(this.props.dateSelected.getTime()/1000)) === getDayFromUnix(Math.floor(today.getTime()/1000))){
      isTodaySelected = true;
    }

    if (this.props.dayReport !== undefined){
      return (
        <div className="reportGrid">
          <Segment size="mini" className="fieldInforme">
            <p className="fieldTag">N° informe</p>
            <Button
              style={{margin:"5px", display:"inline-block"}}
              circular
              icon='minus'
              size='mini'
              onClick={() => this.props.setDayReportsIndex("minus")}
            />
            <p className="fieldValue" style={{display:"inline-block"}}>{this.props.dayReportsIndex+1 + " / " + this.props.dayReportsLength}</p>
            <Button
              style={{margin:"5px", display:"inline-block"}}
              disabled={isTodaySelected && this.props.dayReportsIndex == this.props.dayReportsLength-1}
              circular
              icon='plus'
              size='mini'
              onClick={() => this.props.setDayReportsIndex("plus")}
            />
          </Segment>
          <Segment size="mini" className="fieldDate">
            <p className="fieldTag">Fecha</p>
            <Button
              style={{margin:"5px", display:"inline-block"}}
              circular
              icon='minus'
              size='mini'
              onClick={() => this.props.setDateSelectected("minus")}
            />
            <DatePicker
              selected={this.props.dateSelected}
              onChange={(date)=>this.props.setDateSelectected(date)}
              dateFormat="dd/MM/yyyy"
              locale="es"
              maxDate={today}
              showYearDropdown
              highlightDates={[today]}
            />
            <Button
              style={{margin:"5px", display:"inline-block"}}
              disabled={isTodaySelected}
              circular
              icon='plus'
              size='mini'
              onClick={() => this.props.setDateSelectected("plus")}
            />
          </Segment>
          <Segment size="mini" className="fieldHour">
            <p className="fieldTag">Hora</p>
            <p className="fieldValue">{getTimeTextFromUnix(this.props.dayReport.unixtime)}</p>
          </Segment>
          <Segment size="mini" className="fieldCompas">
            <div>
              <p className="fieldTag angulos" style={{width:"30%"}}>Rumbo</p>
              <p className="fieldValue angulos" style={{width:"70%"}}>{Math.round(this.props.dayReport.y2) + "°"}</p>
            </div>
            <div>
              <p className="fieldTag angulos"  style={{width:"30%"}}>Escora</p>
              <p className="fieldValue angulos"  style={{width:"70%"}}>{Math.round(this.props.dayReport.r2) + "°"}</p>
            </div>
            <div>
              <p className="fieldTag angulos"  style={{width:"30%"}}>Cabeceo</p>
              <p className="fieldValue angulos"  style={{width:"70%"}}>{Math.round(this.props.dayReport.p2) + "°"}</p>
            </div>
          </Segment>
          <Segment size="mini" className="fieldViento">
            <div className="fieldVientoReal">
              <p className="fieldTag">Viento real</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.tws2*10)/10 + " nudos"}</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.twd2) + "° (" + roseta(this.props.dayReport.twd2) + ")"}</p>
            </div>
            <div className="fieldVientoAparente">
              <p className="fieldTag">Viento aparente</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.rws2*10)/10 + " nudos"}</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.rwa2) + "° (" + estriborBabor(this.props.dayReport.rwa2) + ")"}</p>
            </div>
          </Segment>
          <Segment size="mini" className="fieldGps">
            <div className="fieldUbicacion">
              <p className="fieldTag">Coordenadas</p>
              <p className="fieldValue">
                {"Lat. " + this.props.dayReport.gpslatdeg + "° " + this.props.dayReport.gpslatmin + "' " + this.props.dayReport.gpslatchar}
              </p>
              <p className="fieldValue">
                {"Lon. " + this.props.dayReport.gpslondeg + "° " + this.props.dayReport.gpslonmin + "' " + this.props.dayReport.gpslonchar}
              </p>
            </div>
            <div className="fieldVelocidadGps">
              <p className="fieldTag">Velocidad GPS</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.gpssog0*100)/100 + " nudos"}</p>
            </div>
            <div className="fieldRumboGps">
              <p className="fieldTag">Rumbo GPS</p>
              <p className="fieldValue" style={{margin:"10px"}}>{Math.round(this.props.dayReport.gpscog0) + "°"}</p>
            </div>
          </Segment>
          <Segment size="mini" className="fieldMeteo">
            <div className="fieldTemperatura">
              <p className="fieldTag">Temperatura</p>
              <p className="fieldValue">
                {Math.round(this.props.dayReport.meteotem*10)/10 + " °C"}
              </p>
            </div>
            <div className="fieldHumedad">
              <p className="fieldTag">Humedad</p>
              <p className="fieldValue">{Math.round(this.props.dayReport.meteohum*10)/10 + " %"}</p>
            </div>
            <div className="fieldPresion">
              <p className="fieldTag">Presión atm.</p>
              <p className="fieldValue">{Math.round(this.props.dayReport.meteopre*10)/10 + " mbar"}</p>
            </div>
          </Segment>
          <Segment size="mini" className="fieldStatus">
            <div className="fieldSistema">
              <p className="fieldTag">Sistema Gaviota</p>
              <p className="fieldValue">
                {sistema(this.props.dayReport.statusgen)}
              </p>
            </div>
            <div className="fieldBateria">
              <p className="fieldTag">Volt. batería</p>
              <p className="fieldValue">{Math.round(this.props.dayReport.voltagenow*100)/100 + " V (" + voltage(this.props.dayReport.voltagenow) + ")"}</p>
            </div>
            <div className="fieldTelefonia">
              <p className="fieldTag">Señal telef.</p>
              <p className="fieldValue">{this.props.dayReport.signalstrength + " dB (" + signal(this.props.dayReport.signalstrength) + ")"}</p>
            </div>
          </Segment>
          <Segment size="mini" className="fieldStatus2">
            <div className="fieldSentina">
              <p className="fieldTag">Sentina</p>
              <p className="fieldValue">{sentina(this.props.dayReport.sysbilgeactive)}</p>
            </div>
            <div className="fieldEscotilla">
              <p className="fieldTag">Escotilla</p>
              <p className="fieldValue">
                {puerta(this.props.dayReport.sysdooropened)}
              </p>
            </div>
            <div className="fieldAlarma">
              <p className="fieldTag">Alarma</p>
              <p className="fieldValue">{alarma(this.props.dayReport.sysdoorwarm)}</p>
            </div>
          </Segment>


        </div>
      );
    }else{
      return("Sin datos");
    }
  }
}
