export function getDateTimeTextFromUnix(unix) {
    var aux = new Date(unix*1000);
    const dd = aux.getDate();
    const mm = aux.getMonth()+1;
    const yyyy = aux.getFullYear();
    const hh = aux.getHours();
    const min = aux.getMinutes();
    const ss = aux.getSeconds();

    var dayOfWeek="";
    switch (aux.getDay()){
      case 0: dayOfWeek= "Domingo";break;
      case 1: dayOfWeek= "Lunes";break;
      case 2: dayOfWeek= "Martes";break;
      case 3: dayOfWeek= "Miércoles";break;
      case 4: dayOfWeek= "Jueves";break;
      case 5: dayOfWeek= "Viernes";break;
      case 6: dayOfWeek= "Sábado";break;
      default : dayOfWeek= "¿?";break;
    }
    var text=null;
    text=dayOfWeek + " " + dd + "/";
    if (mm<10){text+= "0" + mm + "/" + yyyy + " ";}
    else{text+= mm + "/" + yyyy + " ";}
    if (hh<10){text+= "0" + hh +":";}
    else{text+= hh +":";}
    if (min<10){text+= "0" + min +":";}
    else{text+= min +":";}
    if (ss<10){text+= "0" + ss;}
    else{text+= ss;}

    return text;
}

export function getDateTextFromUnix(unix) {
    var aux = new Date(unix*1000);
    const dd = aux.getDate();
    const mm = aux.getMonth()+1;
    const yyyy = aux.getFullYear();

    var dayOfWeek="";
    switch (aux.getDay()){
      case 0: dayOfWeek= "Domingo";break;
      case 1: dayOfWeek= "Lunes";break;
      case 2: dayOfWeek= "Martes";break;
      case 3: dayOfWeek= "Miércoles";break;
      case 4: dayOfWeek= "Jueves";break;
      case 5: dayOfWeek= "Viernes";break;
      case 6: dayOfWeek= "Sábado";break;
      default : dayOfWeek= "¿?";break;
    }
    var text=null;
    text=dayOfWeek + " " + dd + "/";
    if (mm<10){text+= "0" + mm + "/" + yyyy + " ";}
    else{text+= mm + "/" + yyyy ;}

    return text;
}

export function getTimeTextFromUnix(unix,full=0) {
	let dateAux = new Date(unix*1000);
	let hours = dateAux.getHours();
	if (hours<10){hours = "0" + hours;}
	let minutes = dateAux.getMinutes();
	if (minutes<10){minutes = "0" + minutes;}
	if (full === 1){
		let seconds = dateAux.getSeconds();
		if (seconds<10){seconds = "0" + seconds;}
		return hours + ":" + minutes + ":" + seconds;
	}else{
		return hours + ":" + minutes;
	}
}

export function getFirstAndLastUnixOfDay(unix){
	const dateAux = new Date(unix*1000);
	const timeZoneOffset = dateAux.getTimezoneOffset();
	const day = Math.floor((unix - timeZoneOffset * 60)/86400);
	let limits = [];
	limits[0] = day * 86400 + timeZoneOffset * 60;
	limits[1] = limits[0] + 86400
	return limits;
}

export function getDayFromUnix(unix){
	const dateAux = new Date(unix*1000);
	const timeZoneOffset = dateAux.getTimezoneOffset();
	return Math.floor((unix - timeZoneOffset * 60)/86400);
}

export function roseta(d){
  if (d>=337.5){return "N";}
  else if (d>=292.5){return "NO";}
  else if (d>=247.5){return "O";}
  else if (d>=202.5){return "SO";}
  else if (d>=157.5){return "S";}
  else if (d>=112.5){return "SE";}
  else if (d>=67.5){return "E";}
  else if (d>=22.5){return "NE";}
  else {return "N";}
}

export function estriborBabor(d){
  if (d==0){return "Proa";}
  else if (d==-180 || d==180){return "Popa";}
  else if (d>0){return "Estribor";}
  else if (d<0){return "Babor";}
  else {return "¿?";}
}

export function signal(d){
  if (d>=20){return "Alta";}
  else if (d>=15){return "Media-Alta";}
  else if (d>=10){return "Media-Baja";}
  else {return "Baja";}
}
export function voltage(d){
  if (d>=13.2){return "Cargando";}
  else if (d>=12.7){return "Alta";}
  else if (d>=12.4){return "Media-Alta";}
  else if (d>=12.0){return "Media-Baja";}
  else {return "Baja";}
}
export function puerta(d){
  switch (d){
    case 0: return "Cerrada";break;
    case 1: return "Abierta";break;
    default : return "¿?";break;
  }
}
export function sentina(d){
  switch (d){
    case 0: return "Seca";break;
    case 1: return "Inundada";break;
    default : return "¿?";break;
  }
}
export function sistema(d){
  switch (d){
    case 0: return "Ok";break;
    default : return "Error";break;
  }
}
export function alarma(d){
  switch (d){
    case 0: return "Desactivada";break;
    case 1: return "Activada";break;
    default : return "¿?";break;
  }
}

export function statInfo(type,index=0){
  if (type === "options"){
    if (index === 0){
      return (
        [
          {key: 0, value: 0, text: "Velocidad viento real"},
          {key: 1, value: 1, text: "Procedencia viento real"},
          {key: 2, value: 2, text: "Velocidad viento aparente"},
          {key: 3, value: 3, text: "Ángulo viento aparente"},
          {key: 4, value: 4, text: "Rumbo compas"},
          {key: 5, value: 5, text: "Escora"},
          {key: 6, value: 6, text: "Cabeceo"},
          {key: 7, value: 7, text: "Velocidad GPS"},
          {key: 8, value: 8, text: "Rumbo GPS"},
          {key: 9, value: 9, text: "Temperatura en cabina"},
          {key: 10, value: 10, text: "Humedad"},
          {key: 11, value: 11, text: "Presión atmosférica"},
          {key: 12, value: 12, text: "Voltaje batería"},
          {key: 13, value: 13, text: "Señal telefónica"},
        ]
      );
    }else if (index === 1){
      return (
        [
          {key: -1, value: -1, text: "Ninguno"},
          {key: 0, value: 0, text: "Velocidad viento real"},
          {key: 1, value: 1, text: "Procedencia viento real"},
          {key: 2, value: 2, text: "Velocidad viento aparente"},
          {key: 3, value: 3, text: "Ángulo viento aparente"},
          {key: 4, value: 4, text: "Rumbo compas"},
          {key: 5, value: 5, text: "Escora"},
          {key: 6, value: 6, text: "Cabeceo"},
          {key: 7, value: 7, text: "Velocidad GPS"},
          {key: 8, value: 8, text: "Rumbo GPS"},
          {key: 9, value: 9, text: "Temperatura en cabina"},
          {key: 10, value: 10, text: "Humedad"},
          {key: 11, value: 11, text: "Presión atmosférica"},
          {key: 12, value: 12, text: "Voltaje batería"},
          {key: 13, value: 13, text: "Señal telefónica"},
        ]
      );
    }


  }else if (type === "name"){
    switch (index){
      case 0: return "tws2";break;
      case 1: return "twd2";break;
      case 2: return "rws2";break;
      case 3: return "rwa2";break;
      case 4: return "y2";break;
      case 5: return "r2";break;
      case 6: return "p2";break;
      case 7: return "gpssog0";break;
      case 8: return "gpscog0";break;
      case 9: return "meteotem";break;
      case 10: return "meteohum";break;
      case 11: return "meteopre";break;
      case 12: return "voltagenow";break;
      case 13: return "signalstrength";break;
      default : return "outOfRange";break;
    }
  }else if (type === "label"){
    switch (index){
      case 0: return "Velocidad viento real (nudos)";break;
      case 1: return "Procedencia viento real (grados)";break;
      case 2: return "Velocidad viento aparente (nudos)";break;
      case 3: return "Ángulo viento aparente (grados)";break;
      case 4: return "Rumbo compas (grados)";break;
      case 5: return "Escora (grados)";break;
      case 6: return "Cabeceo (grados)";break;
      case 7: return "Velocidad GPS (nudos)";break;
      case 8: return "Rumbo GPS (grados)";break;
      case 9: return "Temperatura cabina (°C)";break;
      case 10: return "Humedad (%)";break;
      case 11: return "Presión atmosferica (mbar)";break;
      case 12: return "Voltaje batería (V)";break;
      case 13: return "Señal telefónica (dB)";break;
      default : return "outOfRange";break;
    }
  }else if (type === "decimalsRounded"){
    switch (index){
      case 0: return 2;break;
      case 1: return 0;break;
      case 2: return 2;break;
      case 3: return 0;break;
      case 4: return 1;break;
      case 5: return 1;break;
      case 6: return 1;break;
      case 7: return 2;break;
      case 8: return 1;break;
      case 9: return 1;break;
      case 10: return 1;break;
      case 11: return 1;break;
      case 12: return 2;break;
      case 13: return 1;break;
      default : return "outOfRange";break;
    }
  }else if (type === "ticks"){
    switch (index){
      case 0: return {max: 25, min: 0, stepSize: 5};break;
      case 1: return {max: 360, min: 0, stepSize: 45};break;
      case 2: return {max: 25, min: 0, stepSize: 5};break;
      case 3: return {max: 360, min: 0, stepSize: 45};break;
      case 4: return {max: 360, min: 0, stepSize: 45};break;
      case 5: return {max: 30, min: -30, stepSize: 5};break;
      case 6: return {max: 15, min: -15, stepSize: 5};break;
      case 7: return {max: 8, min: 0, stepSize: 1};break;
      case 8: return {max: 360, min: 0, stepSize: 45};break;
      case 9: return {max: 50, min: 0, stepSize: 10};break;
      case 10: return {max: 100, min: 0, stepSize: 10};break;
      case 11: return {max: 1025, min: 975, stepSize: 5};break;
      case 12: return {max: 15, min: 10, stepSize: 1};break;
      case 13: return {max: 35, min: 0, stepSize: 5};break;
      default : return "outOfRange";break;
    }
  }
}

export function headerInfo(type,component){
  if (type === "name"){
    switch(component){
      case "MainPanel": return "Informes";break;
      case "Statistics": return "Gráficas";break;
      case "Gallery": return "Galería";break;
      case "Links": return "Links";break;
      default: return "¿?";break;
    }
  }else if (type === "icon"){
    switch(component){
      case "MainPanel": return "anchor";break;
      case "Statistics": return "area graph";break;
      case "Gallery": return "images";break;
      case "Links": return "paper plane";break;
      default: return "¿?";break;
    }
  }
}
