import React, { Component } from 'react';
import { Container, Grid ,  Dimmer, Loader, Image, Segment, Divider} from 'semantic-ui-react';

export default class Links extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }



  render() {

    return (
      <div  style={{height:"100%"}}>
        <div style={{height:"50px"}}>
        </div>
        <div style={{height:"50px"}}>
          <a href="http://cosasdejuan.es" target="_blank">Cosasdejuan.es</a>
        </div>
        <div style={{height:"50px"}}>
          <a href="http://gaviota.cosasdejuan.es/informesGaviota.php" target="_blank">Informes del sistema Gaviota</a>
        </div>
        <div style={{height:"50px"}}>
          <a href="https://sensoraway.com/visor?aventura=1" target="_blank">Velero Aventura en sensoraway.com</a>
        </div>
        <div style={{height:"50px"}}>
          <a href="https://sensoraway.com/visor?embolic=1" target="_blank">Velero Embolic en sensoraway.com</a>
        </div>
      </div>
    );
  }
}

/*

*/
